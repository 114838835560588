import { PixelRatio } from 'react-native';

import { useRouter } from 'next/router';

import { Image } from '@arrived/bricks';
import { Constants } from '@arrived/common';
import { useFeature } from '@arrived/feature-flags';
import { cdnToImageUrl, ikClient } from '@arrived/imagekit';

import { StartPage, StartPageProps } from '../../pageComponents/start';
import { useHandleEmailSubscription } from '../../pageComponents/start/useHandleEmailSubscription';

const source = ikClient.url({
  path: cdnToImageUrl(`${Constants.awsCdnUrl}/images/landing/the-oscar.png`),
  transformation: [{ width: PixelRatio.getPixelSizeForLayoutSize(400).toString() }],
});

export const StartPageOriginal = ({ version }: Pick<StartPageProps, 'version'>) => {
  const router = useRouter();
  const abTestSearchParam = router.query?.['abptff'] as string;

  useFeature(abTestSearchParam);

  const handleEmailSubscription = useHandleEmailSubscription();

  return (
    <StartPage handleEmailSubscription={handleEmailSubscription} version={version}>
      <Image
        aspectRatio={1}
        $gtXs={{ maxWidth: 400 }}
        maxWidth={350}
        width="100%"
        flex={1}
        contentFit="contain"
        source={source}
        responsivePolicy="static"
      />
    </StartPage>
  );
};

StartPageOriginal.hasNav = false;

export default StartPageOriginal;
